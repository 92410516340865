.wrap{
  font-family: var(--Poppins);
  color: var(--main-color);
  max-width: 100vw;
  width:100%;
  overflow: hidden;
  font-size: 1.6rem;
  line-height: 150%;
  &__black{
    background-color: var(--back-black);
  }
  &__grey{
    background-color: var(--back-grey)
  }
}


.container{
  width: 100%;
  max-width: 144rem;
  margin: 0 auto;
}

.section{
  &__button{
    font: 500 1.4rem/2.1rem var(--Poppins);
    letter-spacing: 0.03em;
    background-color: var(--color-subcolor);
    padding: 1.2rem 4rem ;
    color: var(--color-white);
    border-radius: 0.2rem;
    display: inline-block;
    transition: 0.2s all ease-in;
    text-transform: uppercase;
    &:hover{
      transition: 0.2s all ease-in;
      box-shadow: 0 0 2rem 0 var(--color-subcolor);
    }
  }
  &__title{
    & > *{
      font: 700 3.2rem/136% var(--Roboto);
      text-transform: uppercase;
      color: var(--color-white);
      position: relative;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
    &-main{
      text-align: center;
      margin:5.2rem 0 3.7rem;
      position: relative;
      & > *{
        color: var(--color-subcolor);
        font: 700 4rem/136% var(--Roboto);
        letter-spacing: 0.02em;
        text-transform: uppercase;
      }
    }
    &-underline{
      text-align: center;
      margin-bottom: 3.7rem;
      position: relative;
      & > *{
        color: var(--color-subcolor);
        font: 600 3.6rem/136% var(--Poppins);
        text-transform: uppercase;
        text-align: center;
        position: relative;
        &:after{
          content: "";
          position: absolute;
          bottom: -1.5rem;
          left: 50%;
          transform: translateX(-50%);
          width: 8.9rem;
          height: 0.3rem;
          background-color: var(--color-subcolor);
        }
      }
    }
  }
  &__modal{
    display: none;
    min-width: 30rem;
    background: var(--color-white);
    border-radius: 0.2rem;
    &-content{
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 2.4rem;
      font-family: var(--Poppins);
    }
  }
  &__circle{
    &-item{
      width: 95.7rem;
      height: 71.3rem;
      background: var(--gradient-circle);
      opacity: 0.5;
      position: absolute;
      z-index: 0;
      &:first-child{
        left: -20%;
        top: -3rem;
      }
      &:nth-child(2){
        top: 38rem;
        right: -40%;
      }
      &:nth-child(3){
        left: -40%;
        bottom: 3rem;
      }
    }
  }
}

///+++
.header{
  background-color: var(--back-black);
  overflow: visible;
  &__container{
    padding: 6.2rem 6rem 6.3rem 6.4rem;
    overflow: visible;
  }
  &__block{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo{
    display: block;
    &-img{
      width: 18.2rem;
      height: 2rem;
    }
  }
  &__right{
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 50%;
      left: calc( 50% + 4.5rem);
      transform: translateY(-50%) rotate(-90deg);
      right: 0.5rem;
      width: 0.7rem;

      height: 0.5rem;
      background: url("../img/ARR.svg") center/0.7rem 0.5rem  no-repeat ;
    }
  }
  &__drop{
    width: max-content;
    display: none;
    position: absolute;
    top: 2.6rem;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    height: max-content;
    background: var(--back-grey);
    border-radius: 0.2rem 0 0 0.2rem;
    z-index: 1;
    min-width: 14.3rem;
    &-item{
      position: relative;
      text-transform: initial;

      &:not(:last-child)::before{
        content: '';
        position: absolute;
        width: 85%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 0.1rem solid #4F4F4F;
      }
    }
    &-link{
      text-align: left;
      display: block;
      font: 400 1.6rem / 185% var(--Poppins);
      color:var(--color-white) ;
      width: 100%;
      cursor: pointer;
      padding: 0.7rem 1.1rem;
      transition: all 0.2s ease-in;
      &:hover{
        transition: all 0.2s ease-in;
        color: var(--color-subcolor);
      }
    }
    &-right{
      display: none;
      position: absolute;
      right: -50%;
      padding: 0 1.1rem;
      width: max-content;
      top: 0;
      transform: translateX(50%);
      min-width: 20rem;
      height: max-content;
      background: var(--back-grey);
      border-radius: 0 0.2rem 0.2rem 0;
    }
    &-inside{
      transition: all 0.2s ease-in;
      &:hover .header__drop-right{
        transition: all 0.2s ease-in;
        display: block;
        right: 0;
        z-index: 3;
        transform: none;
        left: 100%;
      }
    }
    &-open{
      position: relative;
      padding-right: 0.8rem;
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -0.3rem;
        width: 0.7rem ;
        height: 0.5rem ;
        background: url("../img/ARR.svg") center/0.7rem 0.5rem  no-repeat ;
      }
    }
    &-click{
      align-items: flex-end;
      display: flex;
    }
  }
  &__button{
    font: 500 2.4rem/ 158% var(--Roboto);
    color: var(--color-white);
    display: block;
    width: max-content;
    cursor: pointer;
    transition: all 0.2s ease-in;
    text-transform: uppercase;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.2rem;
      background-color: var(--color-subcolor);
    }
    //&:hover{
    //  transition: all 0.2s ease-in;
    //  &:after{
    //    content: '';
    //    position: absolute;
    //    left: 0;
    //    bottom: 0;
    //    width: 100%;
    //    height: 0.2rem;
    //    background-color: var(--color-subcolor);
    //  }
    //
    //}
    &-wrap{
      position: relative;
    }
    &-drop{
      align-items: flex-end;
      top: 6rem;
      right: 0;
      left: auto;
      transform: none;
      text-align: right;
    }
  }
  &__menu{
    display: flex;
    justify-content: flex-start;
    gap: 2.5rem;
    &-item{
      //transition: all 0.1s ease-in;
      position: relative;
      box-sizing: content-box;
      transition: all 0.1s ease-in;

      &:hover {
        transition: all 0.2s ease-in;
        &:after{
          content:'';
          transition: all 0.2s ease-in;
          position: absolute;
          width: 100%;
          height: 0.1rem;
          bottom: 0;
          left: 0;
          background:var(--color-subcolor) ;
        }
      }
      &:hover .header__drop{
        display: block;
        z-index: 3;
      }
      & > *{
        font: 900 1.6rem/158% var(--Roboto);
        text-transform: uppercase;
        color:var(--color-white);
        cursor: pointer;
      }
    }
  }
  &__burger{
    width: 4rem;
    height: 4rem;
    display: none;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    border-radius: 100% ;
    cursor: pointer ;
    position: relative;
    & > *{
      width: 2rem;
      height: 0.1rem;
      background: var(--color-white);
      border-radius: 0.2rem;
      transition: .7s ease;
      &:not(:last-child){
        margin-bottom: 0.6rem;
      }
    }
    &-open{
      span{
        display: block;
        position: absolute;
        margin: 0;
        transition: .7s ease;
        &:nth-child(1) {
          transform: rotate(-45deg);
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}

.banner{
  position: relative;
  height: auto;
  &:after{
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--color-banner);
    position: absolute;
    z-index: -1 ;
  }
  &__container{
    padding: 29.8rem 16.2rem 24.4rem;
  }
  &__title{
    max-width: 62.6rem;
    //text-align: center;
    &-right{
    margin-left: auto;
    }
    & > h1{
      font: 900 4rem/113% var(--Roboto);
      letter-spacing: 0.03em;
      color: var(--color-white);
      margin-bottom: 3.4rem;
      text-align: left;
    }
    a{
      margin: 0 auto;
    }
  }
  &__img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    z-index: -1;
  }

}

.title{
  position: relative;
  &__left>*{
    text-align: left;
  }
  padding: 0 0 1.1rem ;
  &:after{
    content: '';
    position: absolute;
    width: 6.9rem;
    height: 0.2rem;
    background-color: var(--color-subcolor);
    bottom: 0;
    left: 0;
  }
}



.youtube{
  color: var(--color-white);
  background: var(--back-index);
  &__container{
    padding: 12.7rem 0 9.9rem 9rem;
  }
  .slick-list{
    padding-left: 0 !important;
    padding-right: 10rem;
  }
  &__list{
    margin-top: 2.6rem;
    display: flex;
    justify-content: flex-start;
    position: relative;
  }
  &__item {
    max-width: 42.9rem;
    width: 100%;
    margin-right: 0.9rem;
    padding: 2.3rem 1.5rem 1.5rem;
    background: #232323;
    height: 34.5rem;
    overflow: hidden;
    transition: 0.2s all ease-in;

    &:hover {
      transition: 0.2s all ease-in;
      box-shadow: 0 0 2rem 0 var(--color-back-black);
    }
  }

  &__img{
    margin-bottom: 1.7rem;
    height: 22.2rem;
  }
  &__title{
    margin-bottom: 0.1rem;
    color: var(--color-white);
    & > *{
      font: 700 2.4rem/136% var(--Roboto);
      height: 3.2rem;
      overflow: hidden;
      letter-spacing: 0.04em;
      text-transform: capitalize;
    }
  }
  &__subtitle{
    & > *{
      font: 400 1.6rem/136% var(--Roboto);
      letter-spacing: 0.015em;
    }
  }
  &__block{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.slick{
  &-previous, &-nextt{
    width: 3.4rem;
    height: 3.4rem;
    position: absolute;
    top: -7rem;
    right: 2rem;
    background: none;
    border: none;
    &:after{
      top: 0;
      left: 0;
      width: 3.4rem;
      height: 3.4rem;
      content: "";
      display: inherit;
      background-size: 3.4rem 3.4rem;
      background-image: url("../img/ARRMOT.svg");
      background-repeat: no-repeat;
      position: absolute;
    }
  }
  &-previous{
    right: 7rem;
    &:after{
      transform: rotateY(180deg);
    }
  }
  &-disabled{
    &:after{
      background-image: url("../img/ARRDIS.svg");
      transform: rotateY(0deg);
    }
  }
  &-nextt.slick-disabled{
    &:after{
      transform: rotateY(180deg);
    }
  }
}
.brands{
  text-align: center;
  position: relative;
  padding-top: 10rem;
  .section__title{
    margin-bottom: 3.6rem;
  }
  &__container{
    padding: 4.4rem 0 3.8rem;
  }
  .slick-list{
    padding-left: 0 !important;
    padding-right: 10rem;
  }
  &__wrap{
    background: var(--color-brands);
  }
  &__list{
    display: flex;
  }
  &__img{
    width: 9.5rem;
    height: 9.5rem;
    margin: 0 auto 1.2rem;
    img{
      border-radius: 100%;
    }
  }
  &__item{
    margin: 0 3rem;
  }
  &__subtitle{
    & > *{
      font: 500 1.8rem/136% var(--Roboto);
      color: var(--color-white);
      text-align: center;
      letter-spacing: 0.005em;
      transition: 0.2s all ease-in;
      &:hover{
        transition: 0.2s all ease-in;
        color: var(--color-subcolor);
      }
    }
  }
}

.aboutmain{
  &__container{
    padding: 10rem 13rem;
    text-align: center;
    display: flex;
    align-items: center;
    gap:7rem;
  }
  &__block{
    width: 40%;
  }
  h2{
    font: 900 6.4rem/136% var(--Roboto);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #343334;
    margin-bottom: 1.6rem;
    width: max-content;
  }
  p{
    font: 400 1.6rem/148.81% var(--Roboto);
    color: var(--color-white);
  }
  &__img{
    width: 60%;
  }
}
.listen{
  color: var(--color-white);
  background-color: var(--back-grey);
  &__container{
    padding: 6rem 0 6rem 9rem;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 2rem;
    gap: 1rem;
  }
  &__item{
    width: calc((100% - 5rem)/6);
    background-color: var(--back-greyBack);
    transition: 0.2s all ease-in;
    &:hover{
      transition: 0.2s all ease-in;
      box-shadow: 0 0 2rem 0 var(--color-back-black);
    }

  }
  &__link{
    padding: 1rem 1.1rem 2rem;
    display: block;
    transition: 0.2s all ease-in;
    &:hover{
      transition: 0.2s all ease-in;
      color: var(--color-subcolor)!important;
    }
  }

  &__img{
    margin-bottom: 0.9rem;
    max-height: 19.5rem;
    max-width: 19.8rem;
  }
  &__title{
    margin-bottom: 0.1rem;
    color: var(--color-white);
    & > *{
      font: 500 2rem/136% var(--Roboto);
      text-transform: capitalize;
      letter-spacing: 0.015em;
    }
  }
  &__subtitle{
    & > *{
      font: 500 1.6rem/136% var(--Roboto);
      letter-spacing: 0.015em;
    }
  }
  &__block{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

}

.shop{
  color: var(--color-white);
  &__container{
    padding: 12rem 0 10.6rem 9rem;
  }
  .slick-list{
    padding-left: 0 !important;
    padding-right: 10rem;
  }
  &__list{
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-top: 2rem;
  }
  &__item{
    margin-right: 2.3rem;
    transition: 0.2s all ease-in;
    &:hover{
      transition: 0.2s all ease-in;
      box-shadow: 0 0 2rem 0 var(--color-back-black);
    }
  }
  &__link{
    display: block;
  }
  &__img{
    margin-bottom: 1.2rem;
    max-height: 38.5rem;
    max-width: 32rem;
  }
  &__title{
    color: var(--color-white);
    h3 {
      font: 500 2.4rem/124% var(--Roboto);
      letter-spacing: 0.04em;
      margin-bottom: 0.7rem;
    }
    h4 {
      font: 400 2.4rem/124% var(--Roboto);
      letter-spacing: 0.04em;
      color: #5C5D5F;
    }
  }
  &__best{
    position: relative;
    &:after{
      content: 'BEST SELLER';
      position: absolute;
      top: 0rem;
      right: -1.5rem;
      color: var(--color-white);
      background-color: var(--color-subcolor);
      padding: 0.8rem 2.2rem;
    }
  }

}



.contact{
  background-color: var(--back-grey);
  &__title{
    margin-top: 2.2rem;
  }
  &__container{
    padding: 17.6rem 7.7rem 12.3rem;
    display: flex;
    justify-content: flex-start;
    gap: 15rem;
    align-items: center;
    & > div{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    & > div:first-child{
      width: 50%;
    }
    & > div > *{
      margin-bottom: 3rem;
    }
  }
  h2{
    font: 500 3.2rem/136% var(--Poppins);
    letter-spacing: 0.015em;
    text-transform: capitalize;
    color: var(--color-subcolor);
  }
  h3{
    font: 300 2.8rem/136% var(--Poppins);
    letter-spacing: 0.015em;
    text-transform: capitalize;
    color: var(--color-white);
    margin-bottom:2.3rem ;
  }
  h4{
    font: 300 2.4rem/136% var(--Poppins);
    letter-spacing: 0.015em;
    color: var(--color-white);
  }
  a {
    font: 500 1.4rem/2.1rem var(--Poppins);
    letter-spacing: 0.03em;
    background-color: var(--color-subcolor);
    padding: 1.2rem 4rem ;
    color: var(--color-white);
    border-radius: 0.2rem;
    display: inline-block;
    transition: 0.2s all ease-in;
    text-transform: uppercase;
    &:hover{
      transition: 0.2s all ease-in;
      box-shadow: 0 0 2rem 0 var(--color-subcolor);
    }
  }

}


///+++++
.footer{
  background-color: var(--color-back-black);
  &__container{
    padding: 5.7rem 4.3rem 3.4rem 5.5rem  ;
  }
  &__bottom{
   margin-top: 2.7rem ;
      & > *{
        text-align: center;
        color: var(--color-white);
        font: 400 1.2rem/2.1rem var(--Poppins);
        letter-spacing: 0.02rem;
      }
  }
  &__block{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo{
    width: 20.6rem;
    height: 2.26rem;
    display: block;
  }
  &__list{
    display: flex;
    gap: 1.7rem;
    align-items: center;
  }
  &__item{
    & > *{
      font: 500 1.6rem/158% var(--Roboto);
      text-transform: uppercase;
      color: var(--color-white);
      &:hover{
        color: var(--color-subcolor);
      }
    }
  }
  &__social{
    display: flex;
    justify-content: flex-start;
    gap: 1.2rem;
    &-item{
      transition: .2s ease;
      &:hover{
        filter: invert(25%) sepia(86%) saturate(6103%) hue-rotate(356deg) brightness(87%) contrast(83%);
      }
    }
    &-img{
      width: 2rem;
      height: 2rem;
    }
  }
}


//+++++++++
.speakers{
  &__container{
    padding: 0rem 8.6rem 21.8rem 11.5rem;
  }
  &__list{
    display: flex;
    justify-content: flex-start;
    gap: 5.5rem;
    flex-wrap: wrap;
    margin-top: 7.7rem;
  }
  &__img{
    width: 100%;
    max-height: 22.2rem;
    img{
      display: block;
    }
  }
  &__item{
    width: calc((100% - 16.5rem )/4);
    transition: 0.2s all ease-in;
    &:hover{
      .speakers__img{
        box-shadow: 0 0 3rem 0 var(--color-back-black);
        transition: 0.2s all ease-in;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    & > *{
      display: list-item;
    }
  }
  &__subtitle{
    padding-top: 1.8rem;
    & > *{
      text-align: center;
      letter-spacing: 0.015em;
      font: 600 1.6rem/136.31% var(--Poppins);
      color: var(--color-white);
    }
  }
}

///+++++
.speeches{
  background-color:var(--back-grey);
  &__search{
    max-width: 85.2rem;
    width: 100%;
    background-color: var(--color-search);
    position: relative;
    margin: 0 auto;
    &:after{
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 3.7rem;
      width: 3rem;
      height: 3rem;
      background: url("../img/SEARCHH.svg") center/3rem 3rem  no-repeat ;
    }
    &-item{
      padding:2.5rem 0 2.5rem 11rem ;
      width: 100%;
      border: none;
      background-color: inherit;
      font: 400 1.6rem/136.3% var(--Poppins);
      color: var(--color-white);
      &::placeholder{
        font: 400 1.6rem/136.31% var(--Poppins);
        color: #B6B6B6;
      }

    }
  }
  &__container{
    padding: 7.4rem 11rem 21.2rem;
    &-top{
      padding: 0 0 1.6rem;
    }
  }
  &__filter{
    display: flex;
    justify-content: space-between;
    padding:8.1rem 13.8rem 0.11rem 12.6rem;
    & > a {
      display: block;
      padding: 0 2rem 0.5rem;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      text-align: center;
      font: 400 2.4rem/136.3% var(--Poppins);
      color: var(--color-white);
      transition: .2s ease;
    }
    &-active {
      position: relative;
      color:var(--color-subcolor)!important;
      font-weight:600!important;
      transition: .2s ease;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.3rem;
        border-radius: 3rem;
        background-color: var(--color-subcolor);
      }
    }
  }
  &__list{
    display: flex;
    gap:6.7rem;
    flex-wrap: wrap;
  }
  &__img{
    width: 100%;
    max-height: 25.4rem;
    margin-bottom: 1.8rem;
    img{
      display: block;
    }
  }
  &__item{
    width: calc((100% - 20.1rem )/4);
    transition: 0.2s all ease-in;
    //height: max-content ;
    &:hover{
      transition: 0.2s all ease-in;
      box-shadow: 0 0 3rem var(--color-back-black);
    }

    //transition: .2s ease;
    //&:hover{
    //  box-shadow: 0 0 2rem 0 var(--color-subcolor);
    //  transition: .2s ease;
    //
    //}
  }
  &__title{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    padding-bottom: 1rem;
    & > *{
      margin-bottom: 0.6rem;
      font: 600 1.6rem/136.31% var(--Poppins);
      color: var(--color-white);
      letter-spacing: 0.04em;
    }
    & > h4{
      font-weight: 300;
      letter-spacing: 0.015em;
    }
  }

  &__one{
    padding: 7rem 11rem;
    display: flex;
    gap: 3rem;
    position: relative;
    z-index: 2;
    color: var(--color-white);
    &-wrap{
      position: relative;
      &:after{
        content:'';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--color-black);
        opacity: 0.7;
        z-index: 1;
      }
      & > img{
        position: absolute;
        display: block;
        top: 0;
        left: 0;
      }
    }
    &-img{
      max-width: 35rem;
      max-height: 35rem;
      height: 100%;
      width: 100%;
      border: 0.1rem solid var(--color-white);
    }
    &-info{
      width: 66%;
      &>a{
        font: 500 1.4rem/2.1rem var(--Poppins);
        letter-spacing: 0.03em;
        background-color: var(--color-subcolor);
        padding: 1.2rem 4rem ;
        color: var(--color-white);
        border-radius: 0.2rem;
        display: inline-block;
        transition: 0.2s all ease-in;
        text-transform: uppercase;
        &:hover{
          transition: 0.2s all ease-in;
          box-shadow: 0 0 2rem 0 var(--color-subcolor);
        }
      }
    }
    &-date{
      font: 400 2rem/136.31% var(--Poppins);
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
    &-title{
      font: 600 4.2rem/136.31% var(--Poppins);
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    &-author{
      font: 600 2rem/136.31% var(--Poppins);
      text-transform: uppercase;
      margin-bottom: 1rem;
      a{
        color: var(--color-subcolor);
      }
    }
    &-desc{
      font: 400 1.6rem/136.31% var(--Poppins);
      margin-bottom: 2rem;
    }
  }
  &__releases > *{
    font: 600 4rem/136.31% var(--Poppins);
    color: white;
    margin-bottom: 3rem;
  }
}


.team{
  &__list{
    display: flex;
    justify-content: flex-start;
    margin: 5.5rem -2.6rem 0 ;
    flex-wrap: wrap;
  }
  &__img{
    max-width: 24.8rem;
    max-height:26.1rem;
    margin: 0 auto 1.7rem;
    img{
      border-radius: 0.8rem;
    }
  }
  &__item{
    width: calc((100% - 15.6rem)/4);
    margin: 0 1.9rem 7rem;
    text-align: center;
    height: 100%;
    & > a{
      border-radius: 0.8rem;
      display: block;
      padding: 1.5rem 1.5rem 3rem;
      transition: .2s ease;
      &:hover{
        box-shadow: 0 0 2rem 0 var(--color-back-black);
        transition: .2s ease;
      }
    }
  }
  &__name{
    margin-bottom: 0.4rem;
    & > *{
      font: 600 1.6rem/136% var(--Poppins);
      color: var(--color-white);
      letter-spacing: 0.04em;
      text-transform: capitalize;
    }
  }

  &__position{
    text-align: center;
    & > *{
      font: 400 1.4rem/136% var(--Poppins);
      color: var(--color-subcolor);
      letter-spacing: 0.04em;
    }
  }
}


///++++
.blog{
  &__container{
    padding: 0 0 10rem 9rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__img{
    max-width: 39.9rem;
    max-height: 59.9rem;
    margin-right: 11.5rem;
    width: 100%;
    height: 100%;
  }
  &__block{
    width: 100%;
  }
  &__social{
    padding: 2.2rem 2.8rem ;
    width: 100%;
    background: var(--back-grey);
    margin-bottom: 4.8rem;

    &-item{
      margin-right: 1.5rem;
      &:hover{
        filter: invert(72%) sepia(33%) saturate(4956%) hue-rotate(177deg) brightness(101%) contrast(93%);
      }
    }
    &-img{
      width: 4rem;
      height: 4rem;
    }
  }
  &__text{
    padding-right: 2rem;
    &>*{
      letter-spacing: 0.03em;
      margin-bottom: 1.4rem;
      color: var(--color-white);
    }
    h2{
      font: 700 4.8rem/126% var(--Poppins);
    }
    p{
      max-width: 65.7rem;
      font: 400 1.8rem/126% var(--Poppins);
    }
  }
  &__link{
    background: var(--color-subcolor);
    font: 700 1.6rem/158% var(--Poppins);
    padding: 1.2rem 4rem;
    color: var(--color-white) ;
    letter-spacing: -0.005em;
    border-radius: 0.4rem;
    margin-top: 4.2rem;
    display: inline-block;
    transition: all 0.2s ease-in;
    &:hover{
      transition: 0.2s all ease-in;
      box-shadow: 0 0 2rem 0 var(--color-subcolor);
    }
  }
}



.part{
  align-items: flex-start;
  &__title{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
  }
  &__text{
    color: var(--color-white);
    margin: 1.2rem 0 2.4rem;
    max-height: 12.8rem;
    overflow: hidden;
    & > h2{
      font: 700 2.4rem/158% var(--Montserrat);
      margin-bottom: 0.8rem;
      letter-spacing: -0.005em;
    }
    & > p{
      font: 400 1.6rem/160% var(--Montserrat);
    }
  }

  &__item{
    margin-bottom: 9.5rem;
    display: flex;
    flex-direction: column;
    align-self: stretch
  }
  &__link{
    margin:0
  }
  .reading__img{
    max-height: 18rem;
  }
}



label.error{
  color: var(--color-white);
  position: absolute;
  top: 4.5rem;
  font-size: 1.6rem;
  left: 0;
}

.hideSection{
 display: none;
}

//++++
.quotes{
  &__container{
    padding: 0 9rem 17.1rem ;
  }
  &__block{
    margin-top: 7.7rem;
    display: flex;
    gap: 3.2rem;
    width: 100%;
    align-items: flex-start;
  }
  &__info{
    color:var(--color-white);
  }
  &__img{
    width: 38rem;
    height: 25rem;
    flex-shrink:0;
  }
  &__title > *{
    font: 600 2.4rem/136% var(--Poppins);
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }
  &__list{
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
  &__item{
    & > *{
      display: flex;
      align-items: flex-start;
      padding: 3.8rem 2.2rem;
      background-color: var(--back-grey);
      gap:2.7rem;
    }
  }
  &__flex{
    display: flex;
    gap: 1.8rem;
    align-items: center;
    letter-spacing: 0.04em;
    margin: 1rem 0;
    & > *{
      position: relative;
      padding-left: 2.7rem;
      & > *{
        font: 300 1.4rem/136% var(--Poppins);
      }

    }
  }
  &__author:before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 2.4rem;
    height: 2.4rem;
    background: url("../img/USERR.svg") center/2.4rem 2.4rem  no-repeat ;
  }
  &__time:before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 2.4rem;
    height: 2.4rem;
    background: url("../img/TIMEE.svg") center/2.4rem 2.4rem  no-repeat ;
  }
  &__text {
    & > *{
      font: 400 1.6rem/175% var(--Poppins);
      max-height: 14rem;
      overflow: hidden;
      display: inline;
    }
    span{
      text-decoration: underline;
      color:var(--color-subcolor)
    }
  }
  &__ads{
    margin: 6.5rem 0 ;
    &-title > *{
      color: var(--color-banner-career);
      margin-bottom: 0.9rem;
      font: 400 1.4rem/136% var(--Poppins);
      letter-spacing: 0.015em;
      text-align: center;
    }
    &-img{
      width: 100%;
      height: auto;
    }
  }
  &__categories{
    color:var(--color-white);
    background-color: var(--back-grey);
    padding: 3.8rem 1.6rem;
    min-width: 28%;
    &-title{
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 3.4rem;
      & > *{
        font: 600 1.9rem/175% var(--Poppins);
      }
      &:after{
        content: '';
        position: absolute;
        bottom: -0.8rem;
        left: 50%;
        transform: translateX(-50%);
        width: 8.9rem;
        height: 0.3rem;
        background-color:var(--color-subcolor)
      }
    }
    &-list{
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }
    li{
      font: 400 2rem/175% var(--Poppins);
      padding-left: 2.4rem;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color:var(--color-subcolor)
      }
    }
  }
  &__subs > * {
    margin-bottom: 3rem;
    color: var(--color-subcolor);
    font: 700 1.8rem/175% var(--Poppins);
    text-align: center;
    display: block;
    text-decoration: underline;
  }
}


//+++
.updates{
  &__container{
    padding: 2.2rem 10rem 10rem 9.2rem;
  }
  &__list{
    margin-top: 8rem;
  }
  &__img{
    width: 45.9rem;
    height: 30.3rem;
  }

}

//+++
.careers{
  &__title{
    h2 {
      font: 600 4rem/136.31% var(--Poppins);
      letter-spacing: 0.04em;
      text-transform: uppercase;
      position: relative;
      color: var(--color-white);
      &:after{
        content: '';
        position: absolute;
        bottom: -2.1rem;
        left: 0;
        width: 8.9rem;
        height: 0.3rem;
        background-color: var(--color-subcolor);
      }
    }
  }
  &__banner{
    min-height: 49.6rem ;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 3.5rem;
    position: relative;
    &:after{
      content:'';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--color-bc);
    }
    & > *{
      position: relative;
      z-index: 1;
      margin: 0 auto;
    }
    & > img{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    & > h1{
      font: 600 4rem/136.31% var(--Poppins);
      color: var(--color-white);
      letter-spacing: 0.04em;
      text-transform: uppercase;
      max-width: 60rem;
    }
    & > h2{
       font: 400 1.6rem/136.31% var(--Poppins);
       color: var(--color-banner-career);
       letter-spacing: 0.015em;
       max-width: 47.5rem;
     }

  }
  ul li {
    font: 400 1.6rem/175% var(--Poppins);
    position: relative;
    padding: 0 0 2rem 2rem;
    &:before{
      content: '';
      position: absolute ;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      top: 1rem;
      left: 0;
      background-color: var(--color-subcolor);
    }
  }
  &.join a, .careers__banner a {
    font: 500 1.4rem/2.1rem var(--Poppins);
    letter-spacing: 0.03em;
    background-color: var(--color-subcolor);
    padding: 1.2rem 4rem ;
    color: var(--color-white);
    border-radius: 0.2rem;
    display: inline-block;
    transition: 0.2s all ease-in;
    text-transform: uppercase;
    &:hover{
      transition: 0.2s all ease-in;
      box-shadow: 0 0 2rem 0 var(--color-subcolor);
    }
  }
}

////++++
.news{
  &__container{
    padding: 5rem 30.3rem 0;
  }
  p{
    font: 400 1.6rem/160% var(--Poppins);
  }
  &__title > *{
    color: var(--color-subcolor);
    font: 700 3.6rem/136.31% var(--Poppins);
    letter-spacing: 0.03em;
    margin-bottom: 2rem;
  }
  &__block{
    display: flex;
    width: 100%;
    gap: 2rem;
    align-items: center;
    margin: 5rem 0;
    & > *{
      width: 50%;
    }
    &-img{
      max-height:22.8rem;
    }
  }
  &__info{
    margin: 2.2rem 0 2rem;
    color: var(--color-white);

  }
  & > *{
    color: var(--color-white);
  }
  & > p{
    margin-bottom: 1.8rem;
  }
  h3{
    font: 600 1.6rem/125% var(--Poppins);
    margin-bottom: 2rem;
  }
  li{
    color: var(--color-white);
    position: relative;
    margin-bottom: 1rem;
    padding-left: 2.6rem;
  }
  ul li{
    &:before{
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border-radius: 100%;
      background-color: var(--color-subcolor);
    }
  }
  ol {
    list-style-type: decimal-leading-zero;
    list-style-position: inside;
  }
  ol li{
    padding-left: 0;
    &::marker{
      color:  var(--color-subcolor);
      font: 400 1.6rem/180% var(--Poppins);
    }
    &>*{
      display: inline;
      padding-left: 0.5rem;
    }
  }
  blockquote{
    margin: 5rem 0;
    min-height: 24.4rem;
    position: relative;
    bottom: 3rem;
    left: 5rem;
    z-index: 1;
    font: 400 1.6rem/180% var(--Poppins);
    max-width: 78.4rem;
    display: flex;
    align-items: flex-end;
      &:after{
        content: "";
        position: absolute;
        bottom: -3rem;
        left: -5rem;
        width: 29.8rem;
        height: 24.4rem;
        z-index: -1;
        background: url("../img/quotess.svg") center/29.8rem 24.4rem  no-repeat ;
      }
  }
}
///++++
.similar{
  &__container{
  padding: 7rem 9rem 10rem 30.3rem;
  }
  &__list{
    display: flex;
    gap:3rem;
    margin-top: 4.4rem;
  }
  &__item{
    width: calc((100% - 9rem)/4);
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    & > a{
      color: var(--color-subcolor);
      text-decoration: underline;
    }
  }
  &__title{
    margin-bottom: 1rem;
  }
  &__title > *{
    font: 600 1.6rem/125% var(--Poppins);
    max-height: 7rem;
    overflow: hidden;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }
  &__text{
    margin-bottom: 1rem;
  }
  &__text > *{
    font: 300 1.4rem/160% var(--Poppins);
    max-height: 7rem;
    overflow: hidden;
  }
  &__img{
    margin-bottom: 2.2rem;
    width: 100%;
    height: 16.6rem;
  }
}



//+++
.job{
  &__container{
    padding: 15rem 5.5rem 4.8rem 17.2rem;
    color: var(--color-white);
    & > p{
      max-width: 73.2rem;
      font: 400 1.6rem/175% var(--Poppins);
      margin: 10rem auto 0;
      text-align: center;
    }
  }
  &__flex{
    display: flex;
  }

  &__block{
    width: 50%;
    & > img{
      display: block;
      width: 33.5rem;
      height: auto;
      margin-bottom: 1rem;
    }
    & > h3{
      font: 400 1.9rem/136% var(--Roboto);
      text-transform: uppercase;
    }
    & > h2{
      font: 600 3.6rem/136% var(--Poppins);
      letter-spacing: 0.04em;
      text-transform: uppercase;
      margin-bottom: 3.7rem;
    }

  }
  &__channel{
    display: flex;
    max-width: 34.5rem;
    flex-wrap: wrap;
    gap:2.5rem;
    margin-top: 5.9rem;
    a {
      display: block;
      width:16rem;
      height: 9.9rem;
    }
  }
}

//+++++
.mission{

  &__container{
    padding: 0 11.5rem 15rem 11.5rem ;
    display: flex;
    align-items: center;
    color:var(--color-white);
    gap: 8rem;
    img{
      max-width: 60rem;
      max-height: 57.3rem;
      width: 50%;
    }
    p{
      margin-top: 7.5rem;
      font: 400 1.6rem/175% var(--Poppins);
      max-width: 50.3rem;
    }
  }
}
//+++++
.culture{
  background: var(--color-career);
  &__container{
    padding: 11.2rem 0 5rem 9.6rem;
    & > div{
      display: flex;
      align-items: center;
      gap: 10rem;

    }
    img{
      max-width: 56.7rem;
      max-height: 102.4rem;
      width: 100%;
      height: 100%;
    }
  }

  ul{
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    width:100% ;
    li{
      padding: 2rem 4rem 2rem 2rem;
      display: flex;
      align-items: center;
      gap: 1.5rem ;
      background-color: var(--color-search);
      width: 100%;
    }
    h3{
      font: 600 1.6rem/136.31% var(--Poppins);
      letter-spacing: 0.04em;
      color:var(--color-subcolor);
      margin: 0 auto;
      width: 28%;
      text-align: center;
    }
    p {
      font: 400 1.6rem/175.81% var(--Poppins);
      color:var(--color-white);
      width: 70%;
    }
  }
}

///++
.life{
  &__container{
    padding: 15rem 9.3rem 12rem;
    display: flex;
    justify-content: space-between;
    gap: 13rem;
    color: var(--color-white);
  }
  img{
    width: 52%;
  }
  h2{
    margin-bottom: 5.5rem;
  }
  p{
    font: 400 1.6rem/175.81% var(--Poppins);
    padding-top: 2rem;
  }

}
//+++
.project{
  &__container{
    padding: 11.6rem 9rem 55rem;
    display: flex;
    justify-content: space-between;
    gap: 3.3rem;
    color: var(--color-white);
  }
  img{
    width: 53%;
  }
  h2{
    margin-bottom: 5.5rem;
  }
  p{
    font: 400 1.6rem/175.81% var(--Poppins);
    padding-top: 2rem;
  }
}
//+++
.creative{
  overflow: visible;
  color: var(--color-white);
  background: var(--color-career);
  position: relative;
  &__container{
    padding: 19rem 7rem 15rem ;
    max-width: 69.6rem;
    margin: 0 auto;
  }
  h3{
    font: 600 4rem/136% var(--Poppins);
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin-bottom: 4.2rem;
  }
  p{
    font: 400 1.6rem/175.81% var(--Poppins);
    margin-bottom: 3.6rem;
  }
  h4{
    margin: 5rem 0;
    font: 600 3.2rem/175.81% var(--Poppins);
    letter-spacing: 0.015em;
    text-transform: capitalize;
  }
  img{
    width: 81.4rem;
    height: 54.3rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: -40rem;
  }
}
//+++
.video{
  background: var(--color-career);
  &__container{
    padding: 0 4rem;
    color: var(--color-white);
    text-align: center;
  }
  h3{
    font: 600 4rem/136.35% var(--Poppins);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin-bottom: 8.5rem;
  }
  &__list{
    display: flex;
    justify-content: center;
    gap: 0;
  }
  &__item {
    width: calc(100%/3);
    height: 25rem;
    position: relative;
    & > iframe {
      width: 100%;
      height: 100%;
    }
  }
  &__info{
    h2{
      position: absolute;
      top: 1rem;
      left: 1rem;
      font: 400 1.6rem/136.35% var(--Poppins);
      max-height:2rem;
      overflow: hidden;
    }
    &-img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      height: 4.8rem;
      width: 6.8rem;
      pointer-events: none;

    }
  }
}
//+++
.join{
  &__container{
    padding: 15rem 2rem 16.7rem  ;
    max-width: 69.8rem;
    margin: 0 auto;
    text-align: center;
    color: var(--color-white);
  }
  h3{
    font: 600 4rem/136% var(--Poppins);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    margin-bottom: 2.9rem;
  }
  h4{
    font: 400 2rem/136% var(--Poppins);
    letter-spacing: 0.015em;
    color: var(--color-subcolor);
    margin-bottom: 2.6rem;
  }
  p{
    font: 400 1.6rem/136% var(--Poppins);
    letter-spacing: 0.015em;
    margin-bottom: 2.6rem;
    color: var(--color-banner-career)
  }
}

//+++
.about{
  color:var(--color-white);
  &__banner {
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--color-grey1);
      opacity: 0.5;
      z-index: 1 ;
    }
    h1{
      color: var(--color-white);
      padding: 19.4rem 2rem 25.5rem;
      font:900 5rem/113.21% var(--Roboto);
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      max-width: 93.5rem;
      margin:  0 auto;
      position: relative;
      z-index: 2 ;
    }
  }
  &__block{
    padding: 6rem 0 7rem ;
    margin: 0 auto;
    p{
      max-width: 63.2rem;
      margin: 5rem auto 0;
      text-align: center;
    }
  }
  &__title{
    &>*{
      text-align: center;
      margin-bottom: 3.4rem;
      position: relative;
      color: var(--color-white);
      font: 700 3.2rem/136% var(--Roboto);
      text-transform: uppercase;
      letter-spacing: 0.02em;
      padding-bottom: 1.1rem;
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 6.9rem;
        height: 0.2rem;
        background-color: var(--color-subcolor);
      }
    }

    &-left{
      &>*{
        text-align: left;
        padding-bottom: 0.7rem;
        margin-bottom: 1.8rem;
        &:after{
          left: 0;
          transform:none;
        }
      }
    }
    &-small{
      &>*{
        font-size: 2.4rem;
        width: max-content;
        text-transform: capitalize;
      }
    }
  }
  &__brand{
    display: flex;
    gap: 20rem;
    justify-content: center;
    margin: 5.6rem 0 5.3rem;
    h4{
      font: 400 2rem/136.31% var(--Roboto);
      letter-spacing: 0.04em;
      width: max-content;
      margin-bottom: 1rem;
      mark{
        color:var(--color-mark);
        background: none;
        -webkit-text-fill-color: initial;
        text-fill-color: transparent;
        padding-left: 1rem;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        font: 400 1.5rem/136.31% var(--Roboto);
      }
    }
  }

  blockquote{
    font: 400 1.6rem/142.81% var(--Roboto);
    max-width: 64.3rem;
    text-align: center;
    margin: 6.5rem auto;
    font-style: italic;
    position: relative;
    z-index: 0;
    &:after,&:before{
      content: '';
      position: absolute;
      width: 3.9rem;
      height: 13.1rem;
      z-index: -1;
      background: url("../img/blockquote.svg") center/3.9rem 13.1rem no-repeat ;
    }
    &:after{
      top: -6.5rem;
      left: -3rem;
    }
    &:before{
      right: -3rem;
      bottom: -3rem;
    }
  }
  &__container{
    padding: 7.5rem 11.3rem ;
    h2{
      letter-spacing: 0.04em;
      text-transform: uppercase;
      font: 700 4rem/136.31% var(--Roboto);
      padding-bottom: 2.7rem;
      position: relative;
      text-align: center;
      &:after{
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 8.9rem;
        height: 0.3rem;
        background: var(--color-subcolor);
      }
    }

    &-flex{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 13.6rem;
      width: 100%;
    }
    p{
      font: 400 1.6rem/142.81% var(--Roboto);
      margin-bottom: 2rem;
    }
    img{
      max-width: 56.8rem;
      max-height: 38.7rem;
      width: 50%;
    }

    h6{
      font: 400 1.6rem/142.81% var(--Roboto);
      max-width: 62.2rem;
      margin-bottom: 2rem;
      font-style: italic;
    }
  }
  &__team{
    padding: 10rem 14.7rem 5rem;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  &__item{
    width: calc(50% - 1rem);
    background-color: #232323;
    color: white;
    transition: 0.2s all ease-in;
    &:hover{
      transition: 0.2s all ease-in;
      box-shadow: 0 0 2rem 0 var(--color-back-black);
    }
    a{
      display: flex;
      gap: 1.9rem;
      padding: 3.1rem 3.1rem 3.1rem 2rem;
    }
    img{
      width: 7rem;
      height: 7rem;
    }
    &-info{
      h4{
        font: 700 2.4rem/136.31% var(--Roboto);
        letter-spacing: 0.04em;
        text-transform: capitalize;
        margin: 0 ;
        padding: 0;
        &:after{
          display: none;
        }
      }
      h5{
        font: 400 1.5rem/136.31% var(--Roboto);
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: var(--color-mark);
        padding-bottom: 1rem;
      }
      p{
        font: 400 1.6rem/136.31% var(--Roboto);
        margin: 0 ;
      }

    }
  }
}
.podcast{
  &__container{
    padding: 0 0 15rem 26.3rem ;
    display: flex;
    gap: 8rem;
    width: 100%;
  }
  &__img{
    width: 50%;
  }
  &__title{
    position: relative;
    margin-bottom: 6.7rem;
    h3{
      font: 700 4rem/136.31% var(--Roboto);
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #343334;
      width: max-content;

    }
    h4{
      position: absolute;
      left: 15rem;
      top: 4.5rem;
      font: 700 3.2rem/136.31% var(--Roboto);
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #F0F0F0;
        width: max-content;
    }
  }
  &__block{
    width: 50%;
    h5{
      font: 500 1.8rem/136.31% var(--Roboto);
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: var(--color-mark);
      margin-bottom: 1.1rem;
    }
    p{
      font: 400 1.6rem/142.81% var(--Roboto);
    }
    a{
      margin-top: 3rem;
      display: inline-block;
      background: var(--color-subcolor);
      color: var(--color-white);
      letter-spacing: 0.03em;
      padding: 1.2rem 4rem;
      font: 500 1.4rem/1.6rem var(--Roboto);
      transition: 0.2s all ease-in;
      &:hover{
        transition: 0.2s all ease-in;
        box-shadow: 0 0 2rem 0 var(--color-subcolor);
      }
    }

  }
}
.sign{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #303233;
    opacity: 0.6;
    z-index: 1;
  }
  img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  &__container{
    padding: 19.5rem 2rem 26.2rem;
    position: relative;
    z-index: 2;
  }
  &__title > *{
    font: 600 3.6rem/136.31% var(--Poppins);
    text-transform: uppercase;
    color: var(--color-white);
    text-align: center;
    margin-bottom: 4rem;
  }
  &__form{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1rem;
    &-input{
      position: relative;
    }
    &-input > input{
      border: none;
      width: 25rem;
      font: 400 1.6rem/136.31% var(--Poppins);
      letter-spacing: 0.015em;
      padding: 1rem 1.6rem 1.2rem;
      background-color: var(--color-white);
      border-radius: 0.2rem;
      &::placeholder{
        color:(--color-banner-career);
      }
    }
    button{
      background-color: var(--color-subcolor);
      border: none;
      border-radius: 0.2rem;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      font: 400 1.4rem/2.1rem var(--Poppins);
      color:var(--color-white);
      padding:1.2rem 4rem
    }
  }
}


.posts{
  &__container{
    padding: 7.4rem 9rem;
  }

  &__list{
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
    margin-top: 2.6rem;
    &-item{
      width: calc((100% - 1.2rem)/3);
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      transition: 0.2s all ease-in;
      background-color: var(--back-greyBack);
      padding: 2.5rem 1.8rem;
      &:hover{
        transition: 0.2s all ease-in;
        box-shadow: 0 0 2rem 0 var(--color-back-black);
        span{
          color: var(--color-subcolor);
          transition: 0.2s all ease-in;
        }
      }
    }
    a{
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    img{
      height: 26.6rem;
    }
    span{
      color: var(--color-subcolor);
      letter-spacing: 0.04em;
      font: 500 2.4rem/124.31% var(--Roboto);
      text-decoration: underline;
    }
  }
  &__text{
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
    margin: 1rem 0 1.5rem ;
    & > *{
      color: var(--color-white);
    }
    h3{
      font: 500 2.4rem/124.31% var(--Roboto);
      letter-spacing: 0.04em;
      margin-bottom: 1.5rem;
    }
    p{
      color: var( --color-mark);
      font: 400 1.6rem/122.81% var(--Roboto);
    }
  }
}

@media only screen and ( max-width:1270px) {
  .quotes__item > *{
    align-items: center;
  }
  .culture__container > div{
    gap: 3rem;
  }
  .culture__container img {
    max-width: 38rem;
  }
  .life__container{
    gap: 4rem;
  }
  .project__container,.life__container,.culture__container{
    align-items: center;
  }
}


@media only screen and ( max-width:1024px) {
  .header__logo{
    margin: 0;
  }
  .help__list{
    margin: 0 -1rem ;
  }
  .help__item{
    width: calc(50% - 2rem);
    margin: 0 1rem 2rem ;
  }
  .releases__list{
    margin: 2rem -1rem 0;
  }
  .releases__item{
    width: calc(50% - 4rem);
    margin: 0 1rem 2rem ;
  }
  .quotes__item > *{
    align-items: center;
  }
}

@media only screen and ( max-width: 666px) {
    .container{
      padding: 5rem 2rem;
    }
    .header__container{
      padding: 2rem;
    }
    .header__burger{
      display: flex;
    }
    .header__menu{
      display: none;
    }
    .header__menu-show{
      padding: 3rem 0;
      position: absolute;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      top: 8.5rem;
      left: 0;
      width: 100%;
      background-color: var(--back-black);
      min-height: 100vh;
      margin: 0;
      z-index: 2;
      overflow: scroll;
    }
    .header__drop-link{
      text-align: center;
      padding: 0 ;
    }
    .header__menu-item{
      margin: 1rem 0;
      width: 100vw;
    }
    .header__drop-right{
      margin: 0 auto;
    }
    .header__menu{
      margin: 0;
      flex-direction: column;
    }
    .main__title{
      &>*{
        margin-bottom: 2rem;
      }
    }
    .main__title> h1{
      font-size: 3.2rem
    }
    .main__title  > h2{
      font-size: 2rem
    }
    .releases__item{
      margin: 0 ;
    }
    .releases__img{
      max-width: 100%;
      max-height: 100%;
    }
    .releases{
      text-align: center;
    }
    .section__title > *{
      font-size: 2.6rem ;
    }
    .releases__head{
      flex-direction: column;
      align-items: center;
    }
    .releases__list{
      margin: 3rem 0 0;
    }
    .releases__linkto{
      margin-top: 1rem;
      font-size: 1.6rem;
    }
    .slick-dots li button:before{
      color: white;
    }
    .slick-dots li.slick-active button:before{
      color: var(--color-white);
    }
    .help__tools{
      margin: 5rem -2rem 5rem;
    }
    .help__tools-item {
      width: calc((100% - 8rem)/2);
      margin: 0 2rem 3rem;
    }
    .help__tools-title > *{
      font-size: 1.6rem;
    }
    .help__tools-title{
      margin-bottom: 2rem;
    }
    .help__tools-img{
      width: 7rem;
      height: 7rem;
    }
    .partners__list{
      margin: 0 -2rem;
      flex-wrap: wrap;
    }
    .partners__item{
      margin: 0 2rem 3rem 2rem;
      width: calc((100% - 8rem)/2);
    }
    .partners__img{
      margin: 0 auto;
    }
    .footer__block{
      flex-direction: column;
      align-items: center;
    }
    .footer__list{
      text-align: center;
      max-height: max-content;
      margin-bottom: 3rem;
      flex-wrap: nowrap;
      max-width: 100%;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
    .footer__list{
      gap:1rem;
      flex-wrap:wrap;
    }
    .footer__item{
      width: calc((100% - 2rem)/3);
    }

    .footer__logo{
      margin-right: 0;
      margin-bottom: 2rem;
    }
    .footer__container{
      padding: 5rem 2rem 2rem;
    }
    .brands__list{
      flex-direction: column;
      margin: 0;
    }
    .brands__item{
      width:100%;
      margin: 0 0 2rem 0;
    }
    .brands__img{
      width: 7rem;
      height: 7rem;
    }
    .brands__subtitle > *{
      font-size: 2rem;
    }
    .help__item{
      margin-bottom: 2rem;
      padding: 2rem 0;
    }
    .partners__title{
      margin-bottom: 4rem;
    }
    .help__subtitle{
      margin: 3rem 0 4rem;
    }

    .header__block{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .header__button{
      text-align: center;
      margin: 0 auto;
      display: none;
    }
    .header__button-wrap{
      width: 100%;
    }
    .header__logo{
      margin: 0 ;
    }
    .header__logo{
      width: 20rem;
      height: auto;
    }
    .speakers__list{
      gap: 2rem;
      margin-top: 0;
    }
    .speakers__item {
      width: calc(50% - 1rem);
    }
    .speakers__item > a{
      display: flex;
      flex-direction: column;
    }
    .speakers__subtitle > *{
      font-size: 1.4rem;
    }
    .speakers__img{
      height: 32vw;
    }
    //.speakers__subtitle{
    //  max-height: 5.5rem;
    //}

    .team__list{
      margin: 0 -1rem;
    }
    .team__item {
      width: calc((100% - 4rem )/2);
      margin: 0 1rem 2rem;
    }
    .team__block{
      padding: 0;
    }
    .team__title{
      margin-bottom: 5rem;
    }
    .team__img{
      margin: 0 auto 2rem;
    }
    .team__item > a{
      padding: 2rem 1rem;
    }
    .team__name{
      margin-bottom: 0.5rem;
    }
    .resources__list-item > *{
      font-size: 1.4rem;
    }
    .resources__title{
      text-align: center;
      margin-bottom: 3rem;
    }
    .resources__block:first-child:after,.resources__block:last-child:after, .resources__block:last-child:before {
      height: 0.5rem;
    }
    .header__drop{
      position: inherit;
      width: 100%;
    }

    .header__menu-item:hover:after{
      display: none;
    }
    .footer__bottom{
      margin-top: 2rem;
    }
    .resources__block:first-child{
      padding-bottom: 5rem;
    }
    .resources__block:last-child{
      padding: 5rem 0
    }
    .footer__info{
      flex-direction: column;
      align-items: center;
    }
    .help__list{
      flex-direction: column;
      margin: 0;
    }
    .help__item{
      width: 100%;
      margin: 0 0 2rem 0;
    }
    .help__item-title > *{
      font-size: 2.6rem;
    }
    .help{
      margin-bottom: 5rem;
    }
    .header__menu-item:hover{
      border: none;
    }
    .header__drop-open{
      padding: 0;
    }
    .header__drop-open:after {
      right: -1.5rem;
    }
    .header__mob{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 1rem;
    }
  .reading__list{
    margin: 0 -1rem;
  }
  .reading__item {
    width: calc((100% - 4rem )/2);
    margin: 0 1rem 2rem;
  }
  .reading__link{
    padding: 1rem 2rem;
    margin: 1.8rem auto;
    font-size: 1.4rem;
  }
  .blog__container{
    flex-direction: column;
    padding: 0;
  }
  .blog__img{
    margin: 0 auto;
    max-width: 25rem;
    img{
      display: block;
    }
  }
  .blog__social{
    justify-content: center ;
    margin-bottom: 0;
  }
  .blog__text h2 {
    font-size: 3.6rem;
  }
  .blog__text p{
    font-size: 1.4rem;
  }
  .blog__text{
    padding: 2rem 3rem;
  }
  .blog__link{
    margin-top: 2rem;
  }
  .blog__link{
    text-align: center;
    padding: 1rem 3rem;
  }
  .releases__list{
    margin: 2rem 0 2rem;
  }
  .header__drop-right{
    transform: none;
    right: 0;
  }
  .releases__item{
    width: 100%;
    margin: 0 ;
  }
  .header__menu-item:hover .header__drop, .header__drop-inside:hover .header__drop-right {
    display: none;
  }
  .drop__inner{
    &.show{
      display: block!important;
    }
  }
  .header__drop-right{
    position: initial;
  }
  .header__right:after{
    transform: translateY(-50%) rotate(0);
  }
  .header__button-drop{
    position: absolute;
  }
  .header__button-drop{
    text-align: center;
  }
  .header__drop-link{
    padding: 2rem ;
  }
  .header__drop-link:hover{
    background-color: initial;
  }




  .part{
    align-items: flex-start;
  }
  .reading__item {
    margin: 0 1rem 4rem;
  }
  .part__text > h2{
    font-size: 1.6rem;
  }
  .part__text > p{
    font-size: 1.4rem;
  }
  .part__text{
    margin: 1rem 0;
  }
  .part .reading__link{
    margin: 0;
  }


  .quotes__block{
    flex-direction: column-reverse;
    margin-top: 5.2rem;
  }
  .quotes__ads-img {
    margin: 0 auto;
    width: 50%;
    height: auto;
  }
  .quotes__ads{
    margin: 2rem 0;
  }
  .quotes__categories{
    width: 100%;
  }
  .quotes__categories li{
    font-size: 1.6rem;
  }
  .quotes__img{
    width: 100%;
    height: auto;
  }
  .quotes__categories-title{
    margin: 0 auto 3rem;
    font-size:1.8rem ;
  }
  .quotes__title > *{
    font-size: 2rem;
  }
  .quotes__item > * {
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.8rem 1.2rem;
  }
  .quotes__flex{
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1.5rem 0;
  }
  .quotes__text > *{
    display: block;
    font-size: 1.4rem;
    max-height:100%;
  }
  .quotes__list{
    gap:2rem
  }


  .similar__list{
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  .similar__item{
    width: calc((100% - 3rem)/2);
  }
  .news__title{
    font-size: 2.2rem;
  }
  .similar__container{
    padding-top: 2rem;
  }
  .news__block{
    flex-direction: column;
  }
  .news__block>*{
    width: 100%;
  }
  .news  p{
    font-size: 1.4rem;
  }
  .news blockquote{
    left: 2rem;
    bottom: 2rem;
    padding-right: 2rem ;
    font-size: 1.6rem;
  }
  .news__block-img{
    max-height: max-content;
  }
  .news__block{
    margin: 3rem 0;
  }



  .speeches__filter{
    width: max-content;
    margin: 0 auto;
    padding: 0;
    gap:2rem;
    flex-direction: column;
    & > a{
      font-size: 2rem;
    }
  }
  .speeches__list{
   gap: 3rem ;
  }
  .speeches__item{
    width: calc((100% - 3rem)/2);
    height: fit-content;
  }
  .quotes__categories-list {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  .footer__social-img{
    width: 3rem;
    height: 3rem;
  }
  .section__title-main > *{
    font-size: 3.2rem;
  }
  .news__title > *{
    font-size: 3.2rem;
  }

  .contact__container{
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
  .contact__container > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .contact__container > div > *{
    margin-bottom: 2rem;
  }
  .contact h2{
    font-size: 2.8rem;
  }
  .contact h3{
    font-size: 2rem;
  }
  .contact h4{
    font-size: 1.8rem;
  }
  .contact__container > div:first-child {
    width: 100%;
  }
  .job__flex{
    flex-direction: column;
    align-items: center;
  }
  .job__block{
    text-align: center;
    & > *{
      margin: 0 auto 2rem;
    }
    width: 100%;
  }
  .job__block ul{
    text-align: left;
  }
  .job__container > p{
    margin-top: 2rem;
    font-size: 1.4rem;
  }
  .job__block > h2{
    font-size: 2.8rem;
  }
  .job__block > h3{
    font-size: 1.8rem;
  }
  .job__channel{
    align-items: center;
    justify-content: center;
    gap:1rem;
  }
  .job__channel a{
    width: 13rem;
    height: 8.04rem;
  }
  .job__block > img{
    width: 25rem;
  }
  .careers ul li{
    font-size: 1.4rem;
  }
  .job__block > h2{
    margin-bottom: 2rem;
  }
  .culture__container > div{
    flex-direction: column;
    margin-top: 6rem;
  }
  .culture ul li{
    flex-direction: column;
    align-items: flex-start;
    & > *{
      width: 100%;
    }
  }
  .culture__container img {
    max-width: 100%;
    max-height: 100vw;
  }
  .culture ul p{
    font-size: 1.4rem;
  }
  .careers__title h2{
    font-size: 3.2rem;
  }
  .mission__container{
    flex-direction: column;
  }
  .mission__container p{
    font-size: 1.4rem;
    margin-top: 4rem;
  }
  .mission__container{
    gap:4rem
  }
  .life__container{
    flex-direction: column-reverse;
  }
  .life__container > img{
    width: 100%;
    margin:  0 auto;
  }
  .life p{
    font-size: 1.4rem;
  }
  .project__container{
    flex-direction: column;
  }
  .project img{
    width: 100%;
  }
  .project p{
    font-size: 1.4rem;
  }
  .project h2,.life__container h2{
    margin-bottom: 4rem;
  }
  .careers__banner{
    padding: 0 1rem ;
  }
  .creative img{
    width: 100%;
    position: relative;
    top: 0;
    height: auto;
  }
  .mission__container img{
    width: 100%;
  }
  .creative h3{
    margin: 2rem 0;
    font-size: 3rem;
  }
  .creative p{
    font-size: 1.4rem;
  }
  .creative h4{
    font-size: 2.6rem;
    margin: 2.5rem 0;
  }
  .join h3{
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  .join p{
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  .join h4{
    margin-bottom: 2rem;
  }
  .video h3{
    font-size: 3rem;
    margin-bottom: 3rem;
  }
  .video__list{
    flex-direction: column;
  }
  .video__item{
    width: 100%;
    height:60vw ;
  }

  .speeches__one{
    flex-direction: column;
    &-img{
      margin: 0 auto;
      max-width: 80vw;
      max-height: 80vw;
      gap: 2rem;
    }
  }
  .speeches__one-info{
    width: 100%;
    &>*{
      margin-bottom: 1rem;
    }
  }
  .speeches__one-wrap:after{
    opacity: 0.9;
  }
  .speeches__one-title{
    font-size: 2.8rem;
  }
  .speeches__one-desc {
    font-size: 1.4rem;
  }

  .speeches__one-date, .speeches__one-author{
    font-size: 1.8rem;
  }
  .speeches__search{
    margin-bottom: 2rem;
  }
  .speeches__search-item{
    padding: 1.5rem 0 1.5rem 10rem;
  }
  .speeches__search:after{
    width: 2rem;
    height: 2rem;
    background-size: 2rem 2rem;
  }
  .speeches__title{
    padding: 0;
  }
  .about__banner h1{
    font-size: 3.4rem;
    padding: 10rem 2rem 10rem;
  }
  .about h2{
    font-size: 3rem;
    padding-bottom: 3rem;
  }

  .about__block p{
    margin: 3rem 0;
    font-size: 1.4rem;
  }
  .about__container{
    flex-direction: column;
    gap: 3rem;
  }
  .about__container-left{
    flex-direction: column-reverse;
  }
  .about__title>*{
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }
  .about__container p{
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }
  .about__container > div{
    width: 100%;
    max-width: 100%;
  }
  .about__container img{
    width: 100%;
  }
  .sign__form{
    flex-direction: column;
    gap: 2rem;
  }
  .sign__title > *{
    font-size: 3rem;
    margin-bottom: 2rem;
  }



  //.posts__list{
  //  gap: 2rem;
  //}
  //.posts__list-item{
  //  width: calc(50% - 1rem);
  //}
  .posts__title{
    margin-bottom: 4rem;
  }
  .posts__text h3{
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  .posts__text p{
    font-size: 1.4rem;
  }
  .posts__list span{
    font-size: 1.6rem;
  }
  .posts__list img{
    height: auto;
  }
  .banner__title > h1{
    font-size: 4rem;
  }



  .youtube__list{
    margin-bottom: 3rem;
  }
  .youtube .slick-list{
    padding: 0!important;
  }
  .youtube .section__title > *{
    padding: 0 0 1rem;
  }
  .brands{
    padding-top:4rem;
  }
  .listen__container{
    padding-bottom: 7rem;
  }
  .posts__container{
    padding-bottom: 6rem;
  }
  .slick-previous, .slick-nextt{
    width: 2.4rem;
    height: 2.4rem;
    top: auto;
    bottom: -5rem;
    left: calc(50% + 1.5rem);
    transform: translateX(-50%);
  }
  .slick-previous{
    left: calc(50% - 1.5rem);
  }

  .slick-previous:after, .slick-nextt:after{
    width: 2.4rem;
    height: 2.4rem;
    background-size: 2.4rem 2.4rem;
  }
  .shop__container{
    padding-bottom: 9rem;
  }
  .about__container-flex{
    flex-direction: column;
    gap: 4rem;
  }
  .about__item{
    width: 100%;
  }
  .about__item img{
    width: 7rem;
  }
  .about__brand{
    flex-direction:column ;
    gap: 5rem;
    margin: 3rem 0 0;
  }
  .about__title-small > *{
    width: auto;
  }
  .about__brand h4{
    width: auto;

  }
  .about__title-small>*{
    font-size: 2rem;
  }
  .podcast__container{
    flex-direction: column;
    gap: 3rem;
    &>*{
      width: 100%;
    }
  }
  .podcast__block p{
    font-size: 1.4rem;
  }
  .podcast__title{
    margin-bottom: 2rem;
  }
  .podcast__title h3, .podcast__title h4{
    font-size: 2.8rem;
    width: auto;
  }
  .podcast__title h4{
    position: relative;
    width: auto;
    top: 0;
    left: 0;
  }
  .section__title-main{
    margin: 2rem 0 ;
  }
  .youtube__item{
    height: 31.5rem;
    margin: 0 1rem;
  }
  .listen__img {
    max-height: initial;
    max-width: initial;
  }
  .aboutmain__container{
    flex-direction: column;
  }
  .aboutmain__container>*{
    width: 100%;
  }
  .aboutmain h2{
    font-size: 2.6rem;
    margin: 0 auto 2rem;
  }

}
@media only screen and ( max-width:480px) {
  .about__brand h4 mark{
    display: block;
    padding-left: 0;
  }
  .blog__social-img{
    width: 3rem;
    height: 3rem;
  }
  .quotes__ads-img {
    width: 100%;
  }
  .speeches__img{
    max-height: fit-content;
    height: auto;
  }

  .posts__list-item{
    width: 100%;
    img{
      height:100%
    }
  }
  .posts__list span{
    font-size: 2rem;
  }
  .youtube__item{
    margin: 0 auto;
    max-width: 100%;

  }
  .brands .slick-list{
    padding-right:0;
  }
  .shop .slick-list{
    padding-right:0;
  }
  .shop__img{
    margin: 0 auto 1.5rem;
  }
  .shop__item{
    margin-right: 1rem;
  }
  .shop__title > *{
    text-align: center;
    font-size: 2rem;

  }
  .brands__container{
    padding: 3rem 2rem 6rem;
  }
}


@media only screen and ( max-width:425px) {
  .about__container a{
    flex-direction: column;

  }
  .team__item > a{
    padding: 2rem 1rem;
  }
  .team__list{
    margin: 0;
  }
  .team__item{
    width: 100%;
    margin: 0 0 1rem 0;
  }
  .footer__item{
    width: 100%;
  }
  .footer__list{
    flex-direction: column;
    align-items: center;

  }
  .footer__subs-title{
    max-width: 100%;
  }

  .footer__subs-input {
    width: 100%;
  }
  .footer__subs-title > *{
    font-size: 1.9rem;
  }
  .blog__social-item{
    margin-right: 1rem;
  }
  .similar__item{
    width: 100%;
  }

  .listen__img{
    max-width: 100%;
    max-height: 100%;
  }
  .listen .slick-list{
    padding-right: 0;
  }
}

@media only screen and ( max-width:375px){
  .speakers__item {
    width: 100%;
  }
  .speakers__img {
    height: 71vw;
  }
  .blog__social-img{
    width: 2rem;
    height: 2rem;
  }
  .speeches__item{
    width: 100%;
  }

}